import { createUseFetch, createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { Periods } from '../types'
import { createUsePagedFetch, PagerOptions } from '@commonstock/client/src/react/createUsePagedFetch'
import { HttpMethods } from '@commonstock/client/src/constants'
import { HydratedUserProfile } from './profile'
import { AssetMention } from '../types/mentions'
import { CommentSummary } from './comment'
import { Likes } from './like'
import { Tag } from './tag'

export enum FeedReason {
  AssetMention = 'asset-mention',
  Interacted = 'interacted',
  Performance = 'performance',
  Featured = 'featured',
  Followed = 'followed',
  FollowedComment = 'followed-comment',
  FollowedLike = 'followed-like',
  FollowedTwitter = 'following-on-twitter',
  Verified = 'verified',
  You = 'you',
  Pinned = 'pinned'
}

export enum FeedResourceType {
  MEMO_PLUS = 'MEMO_PLUS',
  TRADE = 'TRADE',
  POST = 'POST'
}

export const ContentTypeLabels = {
  [FeedResourceType.MEMO_PLUS]: 'memo',
  [FeedResourceType.TRADE]: 'trade',
  [FeedResourceType.POST]: 'post'
}

export enum PositionChangeType {
  OPEN_POSITION = 'OPEN_POSITION',
  INCREMENTAL_BUY = 'INCREMENTAL_BUY',
  INCREMENTAL_SELL = 'INCREMENTAL_SELL',
  CLOSE_POSITION = 'CLOSE_POSITION'
}

export enum OptionSentiment {
  Bearish = 'bearish',
  Bullish = 'bullish'
}

export enum TradePositionType {
  Closing = 'Closing',
  Opening = 'Opening'
}

export type FeedOutput = {
  items: FeedItem[]
}

export type GlobalFeedOutput = {
  items: FeedItem[]
}

export type TradeFeedOutput = {
  items: TradeFeedItem[]
}

export type MemoFeedOutput = {
  items: MemoFeedItem[]
}

export type PostFeedOutput = {
  items: PostFeedItem[]
}

export type FeedItem = {
  uuid: string
  user_uuid: string
  resource_type: FeedResourceType
  resources: FeedItemResources
  created_at: string
  reason_code: FeedReason | null
  reason_text: string | null
}

export type FeedItemResources = {
  memos: { [key: string]: MemoResource }
  trades: { [key: string]: TradeResource }
  users: { [key: string]: HydratedUserProfile }
  assets: { [key: string]: AssetMention }
  posts: { [key: string]: PostResource }
}

type BaseContent = {
  uuid: string
  user_uuid: string
  created_at: string
  created_at_ts: number
  likes: Likes
  comments: CommentSummary
  tags: Tag[]
  related_trades?: string[]
  related_memos?: string[]
  related_posts?: string[]
  bookmarked_at_ts?: string | null
}

export type MemoResource = BaseContent & {
  title: string
  summary: string
  body: string | null
  user_mentions: string[]
  updated_at: string
  published_at: string
  archived_at: string | null
  asset_mentions: string[]
  visibility: string
  reason_code: FeedReason | null
  reason_text: string | null
  links: { [key: string]: Link }
  external_url?: string | null
}

export type OptionContract = {
  expiration: string
  ticker: string
  strike_price: number
  type: string
  side: string
  sentiment: OptionSentiment | null
}

export type TradeResource = BaseContent & {
  asset_identifier: string
  currency: string | null
  transaction_type: 'buy' | 'sell'
  transaction_price: number
  security: string
  // @TODO: discuss with curtis about these props
  status?: string
  quantity?: number
  percent_return: number | null
  position_percent_change: number | null
  position_change_type: PositionChangeType | null
  option_contract: OptionContract | null
  title: string
  trade_type: TradePositionType | null
  details: {
    account_identifier: string
    broker_identifier: string
    broker_name: string
    quantity: number
    status: string
  }
}

export type PostResource = BaseContent & {
  body: string | null
  summary: string | null
  user_mentions: string[]
  updated_at: string
  created_at: string
  published_at: string
  asset_mentions: string[]
  visibility: string
  embedded_link: {}
  links: { [key: string]: Link }
  archived_at: string | null
  media: string[]
  card_url: string | null
  external_url?: string | null
}

export type Link = {
  original_text: string
  url: string
  display_url: string
  title: string
  image: string
  description: string
  site_name: string
  icon: string
  destination: string | null
}

export type MemoFeedItem = FeedItem & {
  resource_type: FeedResourceType.MEMO_PLUS
}
export function isMemoFeedItem(feedItem: FeedItem): feedItem is MemoFeedItem {
  return feedItem && feedItem.resource_type === FeedResourceType.MEMO_PLUS
}
export type TradeFeedItem = FeedItem & {
  resource_type: FeedResourceType.TRADE
}
export function isTradeFeedItem(feedItem?: FeedItem): feedItem is TradeFeedItem {
  return !!feedItem && feedItem.resource_type === FeedResourceType.TRADE
}
export function isTradeOptionFeedItem(feedItem: FeedItem): feedItem is TradeFeedItem {
  return (
    feedItem &&
    feedItem.resource_type === FeedResourceType.TRADE &&
    feedItem.resources.trades[feedItem.uuid]?.security === 'OPTION'
  )
}

export type PostFeedItem = FeedItem & {
  resource_type: FeedResourceType.POST
}
export function isPostFeedItem(feedItem: FeedItem): feedItem is PostFeedItem {
  return feedItem && feedItem.resource_type === FeedResourceType.POST
}

export enum FeedContentType {
  ALL = 'ALL',
  MEMO_PLUS = 'MEMO_PLUS',
  TRADE = 'TRADE'
}

export enum FeedContentOwner {
  CURATED = 'CURATED',
  FOLLOWED = 'FOLLOWED'
}

export type GetFeedParams = {
  json: {
    limit?: number
    last_uuid?: string
    type?: FeedContentType
    direction?: string
    filter?: FeedContentOwner
    alpha?: 'true'
  }
}

export const defaultFeedPager: PagerOptions<any, any> = {
  getCursor: (_page, resource) => {
    const isPost = resource?.methodOptions.method === HttpMethods.Post
    const lastItem = resource?.success?.payload.items.slice(-1)[0]
    const last_uuid = lastItem?.uuid
    return { [isPost ? 'json' : 'query']: { last_uuid } }
  },
  isTerminal: ({ success }) =>
    !!(success?.payload.items && (success?.payload.items.length === 0 || success.payload.items.length < 10))
}

export const useGetFeedV4 = createUsePagedFetch<FeedOutput, GetFeedParams>(
  {
    key: 'following-feed',
    // require version to be specified for now to allow feature flagging back to v3
    path: `/feed/v4`,
    method: HttpMethods.Post,
    independent: false
  },
  defaultFeedPager
)

// Featured trades
export const useGetFeedDiscover = createUseFetch<FeedOutput>({
  key: 'feed-discover',
  path: '/feed/v3/discover',
  method: HttpMethods.Post,
  independent: false
})

const PAGER_LIMIT = 10
export const pagerFeedItemsAfterUuid: PagerOptions<any, any> = {
  getCursor: (page, resource) => {
    const isPost = resource?.methodOptions.method === HttpMethods.Post
    const lastItem = resource?.success?.payload.items.slice(-1)[0]
    const after_uuid = lastItem?.uuid
    return { [isPost ? 'json' : 'query']: { after_uuid, limit: PAGER_LIMIT } }
  },
  isTerminal: ({ success }) => !!((success?.payload.items.length || 0) < PAGER_LIMIT)
}
export type CommentedParams = {
  query: {
    target_uuid: string
    after_uuid?: string
    limit?: number
  }
}

export const USER_COMMENTS_PATH = '/feed/v4/commented'
export const useGetCommented = createUsePagedFetch<FeedOutput, CommentedParams>(
  {
    key: 'commented',
    path: USER_COMMENTS_PATH
  },
  pagerFeedItemsAfterUuid
)

type TopMemosParams = {
  query: {
    last_uuid?: string
    period?: Periods
    limit?: number
  }
}
export const useGetTopMemos = createUsePagedFetch<MemoFeedOutput, TopMemosParams>(
  {
    key: 'top-memos',
    path: '/leaderboard/like-value/v3/content'
  },
  defaultFeedPager
)

export enum ProductMessageImagePosition {
  Left = 'left',
  Right = 'right'
}
export enum ProductMessageColors {
  Light = 'light',
  Dark = 'dark',
  Brand = 'brand',
  Gradient = 'gradient'
}
export enum ProductMessageTypes {
  Modal = 'modal',
  Feed = 'feed'
}

export type ProductMessage = {
  uuid: string
  title: string
  message: string
  style: ProductMessageColors
  image_url: string
  image_placement: ProductMessageImagePosition | ''
  button_title: string
  button_target: string
  button_style: ProductMessageColors | ''
  dismissable: boolean
  message_type?: ProductMessageTypes
}

export const useGetInAppMessages = createUseFetch<ProductMessage[]>({
  key: 'in-app-messages',
  path: '/in-app-messages/list'
})

export const useDismissMessage = createUseFetchAction<ProductMessage[], { json: { uuid: string } }>({
  key: 'dismiss-in-app-messages',
  path: '/in-app-messages/dismiss',
  method: HttpMethods.Post
})

export enum FeedType {
  FOLLOWING = 'Following',
  TOP = 'Top',
  NEW = 'New'
}

export enum GlobalFeedSortType {
  NEW = 'New',
  TOP = 'Top'
}

export enum GlobalFeedPeriodType {
  RECENT = 'recent',
  // DAY = '1d', Removing as we use recent instead of 1D on feed
  WEEK = '1w',
  MONTH = '1m',
  YEAR = '1y',
  ALL = 'all'
}

export type GetGlobalFeedParams = {
  query: {
    sort?: GlobalFeedSortType
    period?: GlobalFeedPeriodType | '1d'
    limit?: number
  }
}

export const globalFeedPager: PagerOptions<any, any> = {
  getCursor: (_page, resource) => {
    const isPost = resource?.methodOptions.method === HttpMethods.Post
    const lastItem = resource?.success?.payload.items.slice(-1)[0]
    const last_uuid = lastItem?.uuid
    return { [isPost ? 'json' : 'query']: { last_uuid } }
  },
  isTerminal: ({ success }) =>
    !!(success?.payload.items && (success?.payload.items.length === 0 || success.payload.items.length < 10))
}

export const GLOBAL_FEED_PATH = '/user-content/feed'
export const useGetGlobalFeed = createUsePagedFetch<GlobalFeedOutput, GetGlobalFeedParams>(
  {
    key: 'get-global-feed',
    path: GLOBAL_FEED_PATH
  },
  globalFeedPager
)
