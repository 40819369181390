/* eslint-disable */

import { AuthTokensKey } from 'src/scopes/auth/constants'
import config from 'src/config'
import { Env } from '../../config'
import { isServer } from 'src/utils/isServer'

// only run GA / GTM in staging and prod
// in the future we will create a segregated account for staging
if (
  !isServer &&
  (window.location.pathname.startsWith('/onboard') || !localStorage.getItem(AuthTokensKey)) &&
  (config.CS_ENV === Env.Staging || config.CS_ENV === Env.Prod)
) {
  /* ## BEGIN GTM  ## */
  const script = document.createElement('script')
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-M9P4R6J');`
  document.head.insertBefore(script, document.head.childNodes[0])

  const noscript = document.createElement('noscript')
  const iframe = document.createElement('iframe')
  iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-M9P4R6J'
  iframe.style = 'display:none;visibility:hidden'
  noscript.appendChild(iframe)
  document.body.insertBefore(noscript, document.body.childNodes[0])
  /* ## END GTM  ## */

  /* ## BEGIN GA ## */
  window.dataLayer = window.dataLayer || []
  function gtag() {
    dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', config.publicGATrackingId)
  /* ## END GA ## */
}
